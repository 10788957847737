import { Grid, Skeleton, useMediaQuery } from '@mui/material';
import React from 'react';
import { 
    PictureGrid, 
    Span, 
    globalStyles,
    RiseText,
} from './styled';
import bgPicture from './bgPicture.png';
import { Global } from '@emotion/react';
import PaintWidget from './components/PaintWidget';
import DropdownButton from './components/DropdownButton';
import usePaint from './hooks';

function Pintura() {
    const xs = useMediaQuery('(min-width:1300px)');

    const {
        dropdownState,
        handleToggleDropdown,
        orderData,
        setOrderData,
        loadingPaint,
    } = usePaint();
  return (
    <Grid container spacing={2} justifyContent="space-around">
        <PictureGrid 
            item 
            xs={12}
            style={{
                backgroundImage: `url(${bgPicture})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                marginTop: '20px'
                }}>
            <PictureGrid container justifyContent='center' alignContent='center' >
                <Grid item xs={12}>
                    <Global styles={globalStyles} />
                    <Span>
                        PINTURA
                    </Span>
                </Grid>
            </PictureGrid>
        </PictureGrid>
        {!xs && <Grid item xs={11}>
            <Grid container>
                <Grid item>
                    <RiseText>
                        Brillante 
                        <DropdownButton 
                            open={dropdownState.brillante}
                            handleOpen={handleToggleDropdown}
                            label={'brillante'}
                        />
                    </RiseText>
                </Grid>
            </Grid>
        </Grid>}
        {xs && <Grid item xs={5}>
            <Grid container>
                <Grid item>
                    <RiseText>
                        Brillante
                    </RiseText>
                </Grid>
            </Grid>
        </Grid>}
        {xs && <Grid item xs={5}>
            <Grid container>
                <Grid item>
                    <RiseText>
                        Matte
                    </RiseText>
                </Grid>
            </Grid>
        </Grid>}
        
        <Grid item xs={xs ? 5 : 11}>
            {dropdownState.brillante ? <Grid container style={{marginBottom: '20px'}}>
                {loadingPaint ? <Skeleton variant="rectangular" height={60} style={{width: '100%', backgroundColor: 'rgb(255 255 255 / 19%)'}}/> : orderData.brillante.map((item, index)=>
                <PaintWidget 
                    key={`brillante-${item.id}`}
                    item={item}
                    last={index === orderData.brillante.length - 1}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    type="brillante"
                />
                )}
            </Grid>: <Grid container style={{borderBottom: '2px solid white'}}></Grid>}
        </Grid>
        {!xs && <Grid item xs={11}>
            <Grid container>
                <Grid item>
                    <RiseText>
                        Matte 
                        <DropdownButton 
                            open={dropdownState.matte}
                            handleOpen={handleToggleDropdown}
                            label={'matte'}
                        />
                    </RiseText>
                </Grid>
            </Grid>
        </Grid>}
        <Grid item xs={xs ? 5 : 11}>
            {dropdownState.matte ? <Grid container>
                {loadingPaint ? <Skeleton variant="rectangular" height={60} style={{width: '100%', backgroundColor: 'rgb(255 255 255 / 19%)'}} /> : orderData.matte.map((item, index)=>
                <PaintWidget 
                    key={`matte-${item.id}`}
                    item={item}
                    last={index === orderData.matte.length - 1}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    type="matte"
                />
                )}
            </Grid>: <Grid container style={{borderBottom: '2px solid white'}}></Grid>}
        </Grid>



        {!xs && <Grid item xs={11}>
            <Grid container>
                <Grid item>
                    <RiseText>
                        Otros
                        <DropdownButton 
                            open={dropdownState.others}
                            handleOpen={handleToggleDropdown}
                            label={'others'}
                        />
                    </RiseText>
                </Grid>
            </Grid>
        </Grid>}
        {xs && <Grid item xs={5}>
            <Grid container>
                <Grid item>
                    <RiseText>
                        Otros
                    </RiseText>
                </Grid>
            </Grid>
        </Grid>}
        {xs && <Grid item xs={5}>
            <Grid container>
                <Grid item>
                    <RiseText>
                        Valvulas
                    </RiseText>
                </Grid>
            </Grid>
        </Grid>}
        
        <Grid item xs={xs ? 5 : 11}>
            {dropdownState.others ? <Grid container style={{marginBottom: '20px'}}>
                {loadingPaint ? <Skeleton variant="rectangular" height={60} style={{width: '100%', backgroundColor: 'rgb(255 255 255 / 19%)'}}/> : orderData.others.map((item, index)=>
                <PaintWidget 
                    helperTextLabel
                    key={`others-${item.id}`}
                    item={item}
                    last={index === orderData.others.length - 1}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    type="others"
                />
                )}
            </Grid>: <Grid container style={{borderBottom: '2px solid white'}}></Grid>}
        </Grid>
        {!xs && <Grid item xs={11}>
            <Grid container>
                <Grid item>
                    <RiseText>
                        Valvulas 
                        <DropdownButton 
                            open={dropdownState.accesories}
                            handleOpen={handleToggleDropdown}
                            label={'accesories'}
                        />
                    </RiseText>
                </Grid>
            </Grid>
        </Grid>}
        <Grid item xs={xs ? 5 : 11}>
            {dropdownState.accesories ? <Grid container>
                {loadingPaint ? <Skeleton variant="rectangular" height={60} style={{width: '100%', backgroundColor: 'rgb(255 255 255 / 19%)'}} /> : orderData.accesories.map((item, index)=>
                <PaintWidget 
                    key={`accesories-${item.id}`}
                    item={item}
                    last={index === orderData.accesories.length - 1}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    type="accesories"
                />
                )}
            </Grid>: <Grid container style={{borderBottom: '2px solid white'}}></Grid>}
        </Grid>
        <Grid item xs={12} style={{height: '50px'}}>

        </Grid>
    </Grid>
  );
}

export default Pintura;