import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_PAINT } from "graphql/queries";
import { useMediaQuery } from "@mui/material";

export default function usePaint(){
    
    const xs = useMediaQuery('(min-width:1300px)');
    const [dropdownState, setDropdownState] = useState(xs ? {
        matte: true,
        brillante: true,
        others: true,
        accesories: true
      } : {
        matte: false,
        brillante: true,
        others: false,
        accesories: false
      });

    const handleToggleDropdown = (data, label) => {
        setDropdownState(prevState => ({
            ...prevState,
            [label]: data
        }));
    };
    const {
        data: paint,
        loading: loadingPaint,
    } = useQuery(GET_PAINT, {
        pollInterval: 120000, // Poll every 120 seconds
      });

    const [orderData, setOrderData] = useState({matte: [], brillante: [], others: [], accesories: []})

    useEffect(() => {
        if (paint && !loadingPaint) {
            const updatedMatte = paint.matte.map(item => ({ ...item, cart: 0 }));
            const updatedBrillante = paint.brillante.map(item => ({ ...item, cart: 0 }));
            const updatedOthers = paint.others.map(item => ({ ...item, cart: 0 }));
            const updatedAccesories = paint.accesories.map(item => ({ ...item, cart: 0 }));

            
            setOrderData({
                matte: updatedMatte,
                brillante: updatedBrillante,
                others: updatedOthers,
                accesories: updatedAccesories
            });
        }
    }, [paint, loadingPaint]);
    
    return {
        dropdownState,
        handleToggleDropdown,
        orderData,
        setOrderData,
        loadingPaint,
    };
}

