import { Grid, styled, Typography, css, Button } from "@mui/material";

export const TitleText = styled(Typography)`
  font-weight: 500;
  font-size: 60px;
  color: white;
  margin-bottom: 30px;

  @media (max-width: 700px) {
    font-size: 40px;
    margin-bottom: 20px;
  }
`;
export const ParagraphText = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  color: white;
  text-align: justify;

  @media (max-width: 700px) {
  font-size: 16px;
  }
`;

export const TextGrid = styled(Grid)`
  margin-top: 80px;

  @media (max-width: 700px) {
      margin-top: 50px;
    }
`;

export const PictureGrid = styled(Grid)`
  position: relative;
  height: 600px;
  @media (max-width: 700px) {
      height: 350px;
    }
`;

// Global styles including @import and body styles
export const globalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap');

  body {
    font-family: 'Raleway', sans-serif;
  }
`;

export const Span = styled('span')`
  position: absolute;
  top: 260px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  text-align: center;
  letter-spacing: 0.1em;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  text-shadow: 4px 4px #ffd000, 10px 10px #000000;

  @media (max-width: 700px) {
    font-size: 50px;
    top: 170px;
    line-height: 70px;
  }
`;

export const LargeText = styled('span')`
  font-size: 123px;
  top: 175px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 60px;
    top: 105px;
  }
`;

export const ActionButton = styled(Button)`
  background-color: #ecb81c;
  color: black;
  font-size: 24px;
  font-weight: 600;
  height: 70px;
  width: 300px;
  border-radius: 15px;
  postition: absolute;
  top: -50px;
  
  &:hover {
    background-color: #0c0c0c; 
    color: white; 
  }

  @media (max-width: 700px) {
    font-size: 22px;
    width: 250px;
    height: 60px;
    top: -45px;
  }
`;