import { gql } from "@apollo/client";

export const GET_PAINT = gql`
  query get_paint {
  matte: main_paint(order_by: {pallete_order: asc}, where: {stock: {_gt: 0}, color_type_id: {_eq: "9a5ea3c3-67e9-46c3-a741-7890255147bf"}}) {
    id
    code_label
    name
    color
    label_color
    stock
    in_order
    price
    product_type_id
  }
  brillante: main_paint(order_by: {pallete_order: asc}, where: {stock: {_gt: 0}, color_type_id: {_eq: "57cb5338-aec1-485b-94e5-94016ff5456f"}}) {
    id
    code_label
    name
    color
    label_color
    stock
    in_order
    price
    product_type_id
  }
  others: main_paint(order_by: {pallete_order: asc}, where: {stock: {_gt: 0}, _or: [{color_type_id: {_eq: "3cd0863b-4e97-43fb-8554-3265c781e315"}}, {color_type_id: {_eq: "d2696346-a036-4e34-a44f-f8c6c4c3beea"}}]}) {
    id
    code_label
    name
    color
    label_color
    color_type {
      name
    }
    stock
    in_order
    price
    product_type_id
  }
  accesories: main_accesories(order_by: {pallete_order: asc}, where: {stock: {_gt: 0}}) {
    id
    code_label
    name
    color
    label_color
    stock
    in_order
    price
    product_type_id
  }
}
`;
