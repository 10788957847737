import React from 'react';
import { 
    Toolbar,
    IconButton,
    Grid,
} from '@mui/material';
import {
    Menu as MenuIcon
} from '@mui/icons-material';
import { AppBar } from './styled';
import Logo from './logo.js';
import CartButton from 'components/CartButton';


function AppBarLayout ({
    showSidebar,
}) {

    return (
        <AppBar position="fixed">
        <Toolbar>
          <Grid container 
          justifyContent="space-between" 
          alignItems="center"
          >
            <Grid item>
              <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={showSidebar}
              style={{position: 'fixed', top: '5px'}}
              >
                <MenuIcon style={{height: '35px', width: '35px'}}/>
              </IconButton>
            </Grid>
            <Grid item>
              <Logo/>
            </Grid>
            <Grid item>
              <CartButton />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
}

export default AppBarLayout;
