import './App.css';
import React from 'react';
import { AppProvider } from './AppContext';
import AppUI from './Main';

function App() {
  return (
      <AppProvider>
        <AppUI />
      </AppProvider>
  );
}

export default App;