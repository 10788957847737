import React, { useState, useContext } from 'react';
import { Popover, IconButton, Typography, Button, Box, Badge, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, LinearProgress } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { AppContext } from 'AppContext'; // Ajusta la ruta según tu estructura de carpetas
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { INSERT_ORDER, UPDATE_STOCK, UPDATE_STOCK_ACCESORIES } from 'graphql/mutations';
import { useNavigate } from "react-router-dom";

// Estilo personalizado para el botón
const StyledButton = styled(Button)`
  background-color: yellow;
  color: black;
  font-weight: 600;
  font-size: 20px;
  &:hover {
    background-color: #f9c700;
  }
`;
const StyledTextField = styled(TextField)`
    .MuiInputBase-input {
        padding: 10px 8px;
        text-align: end;
        font-weight: 600;
        font-size: 14px;
        background-color: #212121;
    }
    & .MuiInputLabel-root {
    color: white;
    font-size: 14px;
  }
        
`;

const CartButton = () => {
  const { selectedItems, setSelectedItems } = useContext(AppContext);
  const [anchorElLocal, setAnchorElLocal] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false); // Estado para el diálogo
  const [dialogConfirmation, setDialogConfirmation] = useState(false); // Estado para el diálogo
  const [phoneNumber, setPhoneNumber] = useState(''); // Estado para el número de teléfono
  const [phoneError, setPhoneError] = useState(''); // Estado para el error del número de teléfono
  const [loadingInsertion, setLoadingInsertion] = useState(false);
  const navigate = useNavigate();
  
  const validatePhoneNumber = (number) => {
    // Expresión regular simple para validar el formato del número de teléfono
    return /^\d{10}$/.test(number);
  };

  // Función para calcular el subtotal
  const getSubtotal = () => {
    const subtotal = selectedItems.reduce((total, item) => total + item.cart * item.price, 0);
    return (subtotal / 100).toFixed(2); // Divide entre 100 para convertir el valor a pesos y luego formatea con dos decimales
  };

  const open = Boolean(anchorElLocal);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorElLocal(event.currentTarget);
  };

  // Calcular el total de piezas en selectedItems
  const totalItems = selectedItems.reduce((sum, item) => sum + item.cart, 0);

  const getTotalItems = () => {
    return selectedItems.reduce((total, item) => total + item.cart, 0);
  };

  const handleGenerateOrderClick = () => {
    setAnchorElLocal(null);
    setDialogOpen(true); // Abre el diálogo
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Cierra el diálogo
  };
  const handleCloseConfirmation = () => {
    setDialogConfirmation({value: false, code: ""});
    navigate("/");
  };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    setPhoneError(''); // Limpiar el error cuando el usuario empieza a corregir el número
  };

  // Insert order
  const [insertOrder, { loading}] = useMutation(INSERT_ORDER);
  const [updatePaintStock, {loading: loadingUpdate}] = useMutation(UPDATE_STOCK);
  const [updateAccesoryStock] = useMutation(UPDATE_STOCK_ACCESORIES);
  
  //Helper data
  const helperInsertOrder = () => {
    const aux = [];
    selectedItems.map((i)=> aux.push({
        paint_id: i.product_type_id === '40a0dddb-2bfa-47a1-a300-01bba87abe6b' ? i.id : null,
        accesory_id: i.product_type_id === 'ad4cfa92-4b4b-489a-add9-6774b86cd1aa' ? i.id : null,
        quantity: i.cart,
        amount: i.price,
      })
    );
    return aux;
  }


  //Insert order function
  const handleConfirmOrder = async () => {
  
    // Validar el número de teléfono
    if (validatePhoneNumber(phoneNumber)) {
      setLoadingInsertion(true);
      // Lógica para la confirmación de la orden
      const items_to_insert = helperInsertOrder();
      const orderInfo = [{
        amount: selectedItems.reduce((total, item) => total + item.cart * item.price, 0),
        quantity: totalItems,
        customer_phone: phoneNumber,
        items : {
          data: items_to_insert,
        }
      }];
      
      insertOrder({ variables: { order_info: orderInfo } })
        .then(async (response) => {
          console.log('Orden realizada', response.data.insert_main_orders.returning[0].items);
          
          for (const item of response.data.insert_main_orders.returning[0].paints) {
            await updatePaintStock({
              variables: {
                paint_id: item.paint.id,
                stock: item.paint.stock - item.quantity,
                in_order: item.paint.in_order + item.quantity,
              }
            });
          }
          for (const item of response.data.insert_main_orders.returning[0].accesories) {
            await updateAccesoryStock({
              variables: {
                accesory_id: item.accesory.id,
                stock: item.accesory.stock - item.quantity,
                in_order: item.accesory.in_order + item.quantity,
              }
            });
          }
          setDialogOpen(false);
          setSelectedItems([]);
          setLoadingInsertion(false);
          setPhoneNumber('');
          setDialogConfirmation({value: true, code: `SE0${response.data.insert_main_orders.returning[0].tracking_number}`});
        })
        .catch((error) => {
          console.error('Error al insertar la orden', error);
        });
    } else {
      setPhoneError('Número de teléfono inválido');
    }
  };

  return (
    <>
      <IconButton
        disabled={totalItems === 0}
        color="inherit"
        onClick={handleClick}
        style={{ position: 'fixed', top: '8px', right: '20px' }}
      >
        <Badge badgeContent={totalItems} color="secondary">
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElLocal}
        onClose={() => setAnchorElLocal(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box padding={2} minWidth={200} style={{ backgroundColor: '#212121' }}>
          <Typography variant="body1" gutterBottom style={{ color: 'white' }}>
            Total de artículos: {getTotalItems()}
          </Typography>
          <Typography variant="h6" gutterBottom style={{ color: 'white' }}>
            Subtotal: ${getSubtotal()}
          </Typography>
          <StyledButton
            variant="contained"
            fullWidth
            onClick={handleGenerateOrderClick}
          >
            Generar orden
          </StyledButton>
        </Box>
      </Popover>
      
      <Dialog 
      open={dialogOpen} 
      onClose={(e, reason) => {
        // Impide el cierre del diálogo al hacer clic fuera o presionar ESC
        if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && loadingInsertion ) {
          e.stopPropagation(); // Evita que el clic fuera del diálogo lo cierre
        }else{
          setPhoneNumber('');
          handleCloseDialog()
        }
      }} 
      sx={{ '& .MuiDialog-paper': { bgcolor: '#121212', color: 'white' } }}>
        <DialogTitle>Confirmar pedido</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Revisa los detalles y confirma.
          </Typography>
          <Grid container  style={{ minHeight: '50px', maxHeight: '288px', overflow: 'inherit', paddingRight: '8px'}}>
            {selectedItems.map((item, index)=>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container>
                  <Grid item>
                    <div style={{backgroundColor: item.color, height: '18px', width: '18px', marginRight: '10px', marginTop: '2px'}}></div>
                  </Grid>
                  <Grid item>
                    <Typography key={`${item.id}-${index}`} variant="body1">
                      {`${item.code_label} ${item.name}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography key={`${item.id}-${index}`} variant="body1">
                  {`${item.cart}`}
                </Typography>
              </Grid>
            </Grid>)}
          </Grid>
          <Grid container justifyContent="space-between" style={{borderTop: '2px solid white'}}>
            <Grid item>
              <Typography variant="body1">
                Total artículos:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {getTotalItems()}
              </Typography>
            </Grid>
          </Grid><Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body1">
                Total
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                ${getSubtotal()}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
          <StyledTextField
            label="Número de teléfono / WhatsApp"
            variant="outlined"
            fullWidth
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={!!phoneError}
            helperText={phoneError}
            inputProps={{ maxLength: 10 }}
            type="tel"
            sx={{ mt: 2, '& .MuiOutlinedInput-root': { color: 'white' }, '& .MuiInputLabel-root': { color: 'white' }, '& .MuiFormHelperText-root': { color: 'red' } }}
          />
          </Grid>
        </DialogContent>
        <DialogActions>
          {loading || loadingUpdate ? <Box sx={{ width: '100%' }}>
            <LinearProgress color="inherit" />
          </Box> : <><Button 
          disabled={loading || loadingUpdate || loadingInsertion}
          onClick={()=>{
            setPhoneNumber('');
            handleCloseDialog();
          }} 
          color="primary">
            Cancelar
          </Button>
          <StyledButton 
            disabled={loading || loadingUpdate}
            onClick={handleConfirmOrder}
            variant="contained"
            style={{fontSize: '14px'}}
            >
            Realizar pedido
          </StyledButton></>}
        </DialogActions>
      </Dialog>
      <Dialog 
      open={dialogConfirmation.value} 
      onClose={(e, reason) => {
        // Impide el cierre del diálogo al hacer clic fuera o presionar ESC
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          e.stopPropagation(); // Evita que el clic fuera del diálogo lo cierre
        }
      }} 
      sx={{ '& .MuiDialog-paper': { bgcolor: '#121212', color: 'white' } }}>
        <DialogTitle>Pedido realizado!</DialogTitle>
        <DialogContent>
        <Grid container justifyContent="space-between" style={{borderTop: '2px solid white'}}>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom >
                Tu código de rastreo es:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" gutterBottom align="justify">
                {dialogConfirmation.code}
              </Typography>
              </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom align="justify">
                {`Recibirás un mensaje de WhatsApp con los detalles de tu pedido.`}
              </Typography>
              </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom align="justify">
                {`Cualquier duda o pregunta comunícate al (668) 836-4094.`}
              </Typography>
              </Grid>
            </Grid>
        </DialogContent>
      <StyledButton 
            onClick={handleCloseConfirmation}
            variant="contained"
            style={{fontSize: '14px'}}
            >
            Listo
          </StyledButton>
      </Dialog>
    </>
  );
};

export default CartButton;
