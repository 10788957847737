import { gql } from '@apollo/client';

// Define la mutación
export const INSERT_ORDER = gql`
  mutation insert_order($order_info: [main_orders_insert_input!]!) {
  insert_main_orders(objects: $order_info) {
    affected_rows
    returning {
      tracking_number
      paints: items(where: {paint_id: {_is_null: false}}){
        id
        amount
        quantity
        paint {
          id
          stock
          in_order
        }
      }
      accesories: items(where: {accesory_id: {_is_null: false}}){
        id
        amount
        quantity
        accesory {
          id
          stock
          in_order
        }
      }
    }
  }
}
`;

export const UPDATE_STOCK = gql`
mutation update_paint_stock($paint_id: uuid!, $stock: Int!, $in_order: Int!) {
    update_main_paint(
      where: { id: { _eq: $paint_id } }
      _set: { stock: $stock, in_order: $in_order }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_STOCK_ACCESORIES = gql`
mutation update_accesory_stock($accesory_id: uuid!, $stock: Int!, $in_order: Int!) {
    update_main_accesories(
      where: { id: { _eq: $accesory_id } }
      _set: { stock: $stock, in_order: $in_order }
    ) {
      affected_rows
    }
  }
`;