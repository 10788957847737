import { Grid, styled, css, TextField, Typography } from "@mui/material";

export const PictureGrid = styled(Grid)`
    position: relative;
    height: 400px;
    @media (max-width: 700px) {
        height: 250px;
    }
`;

export const globalStyles = css`
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap');

    body {
        font-family: 'Raleway', sans-serif;
    }
`;

export const Span = styled('span')`
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 120px;
    text-align: center;
    letter-spacing: 0.1em;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
    text-shadow: 4px 4px #ffd000, 10px 10px #000000;

    @media (max-width: 700px) {
        font-size: 60px;
        top: 120px;
        line-height: 70px;
    }
`;

export const RiseText = styled(Typography)`
    font-size: 50px;
    color: #fff;
    font-weight: 600;
`;

// PaintWidget styles
export const PaintItem = styled(Grid)`
    border-top: 2px solid black;
    border-right: 2px solid black;
    border-left: 2px solid black;
    background-color: #ffffff;
`;

export const AmountTextField = styled(TextField)`
    .MuiInputBase-input {
        padding: 7px 8px;
        background-color: white;
        width: 70px;    
        text-align: end;
        font-weight: 600;
        font-size: 20px;
    }
    @media (max-width: 1300px) {
        .MuiInputBase-input {
            width: 40px;
        }
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
        
`;

export const LabelGrid = styled(Grid)`
    align-content: center;
    border-right: 2px solid black;
    padding: 5px 10px;
    background-color: ${(props) => props.bgcolor || 'black'};
    color: ${(props) => props.textcolor || 'white'};


    @media (max-width: 1300px) {
    padding: 5px;
    width: 120px;
    }
`;

export const LabelText = styled(Typography)`
    font-size: 20px;
    font-weight: 500;
    text-align: left;

    @media (max-width: 1300px) {
        font-size: 16px;
    }
`;

export const AmountGrid = styled(Grid)`
    width: 70px;
    background-color: #dcdcdc;

    @media (max-width: 700px) {
        width: 50px;
    }
`;
export const AmountText = styled(Typography)`
    font-size: 20px;
    font-weight: 500;
`;