import styled from "@emotion/styled";
import AppBarMUI from "@mui/material/AppBar";
import ButtonMUI from "@mui/material/Button";


export const AppBar = styled(AppBarMUI)`
    background-color: #181818;
`;

export const Button = styled(ButtonMUI)`
    border-radius: 5px;
    padding: 3px 25px;
    background-color: #f08080;
    color: white;
    border: none;
    margin-left: auto;
    margin-right: 30px;
    :hover {
        background-color: white;
        color: #1d959a;;
    }
`;