import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { 
    TextGrid,
    ParagraphText,
} from './styled';
import emptyPicture from './empty_artists.png';

function Accesories() {
    const xs = useMediaQuery('(min-width:1300px)');
    const xss = useMediaQuery('(min-width:700px)');
  return (
    <Grid container spacing={2}>
        <TextGrid spacing={2} item xs={12}>
            <Grid container justifyContent='center'>
                <Grid item>
                    <img 
                    alt="empty" 
                    src={emptyPicture} 
                    style={{ 
                        width: xss ? '421px' : '269px', 
                        height: xss ? '385px' : '246px', 
                        marginTop: '100px',
                        marginBottom: '20px'
                        }}    
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent='center'>
                <Grid item xs={xs ? 5 : xss ? 10 : 11}>
                    <ParagraphText>
                    <span style={{fontWeight: '600', fontSize: '26px'}}>¿Eres artista?<br /></span>
                    ¡Queremos colaborar contigo!<br />
                    Siempre estamos en busca de nuevos proyectos y queremos que tú seas parte de ellos.
                    Mantente cerca, ¡porque lo mejor está por venir!
                    </ParagraphText>
                </Grid>
            </Grid>
        </TextGrid>
        <Grid container style={{height: '150px'}}>

        </Grid>
    </Grid>
  );
}

export default Accesories;