import { Grid, styled, Typography } from "@mui/material";

export const ParagraphText = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  color: white;
  text-align: justify;

  @media (max-width: 700px) {
  font-size: 18px;
  }
`;

export const TextGrid = styled(Grid)`
  margin-top: 50px;

  @media (max-width: 700px) {
      margin-top: 30px;
    }
`;
