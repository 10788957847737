import { Grid, IconButton, useMediaQuery } from '@mui/material';
import React from 'react';
import { 
    TextGrid,
    ParagraphText,
} from './styled';
import { Facebook, Instagram, SupportAgent } from '@mui/icons-material';

function Contact() {
    const xss = useMediaQuery('(min-width:700px)');
  return (
    <Grid container spacing={2}>
        <TextGrid spacing={2} item xs={12}>
            <Grid container justifyContent='center'>
                <Grid item>
                    <SupportAgent 
                    style={{ 
                        color: 'white',
                        width: xss ? '350px' : '200px', 
                        height: xss ? '350px' : '200px', 
                        marginTop: '100px',
                        marginBottom: '20px'
                        }} />
                </Grid>
            </Grid>
            
            <Grid container justifyContent='center'>
                <Grid item >   
                    <ParagraphText gutterBottom>
                        <span style={{
                            fontWeight: '600',
                            fontSize: '24px',
                        }}>Contáctanos<br/></span>
                    </ParagraphText>
                 </Grid> 
            </Grid>
            <Grid container justifyContent='center'>
                <Grid item >
                    <ParagraphText>
                        Tel: (668) 836-4094<br/>
                        Correo: sector.noventa@gmail.com<br/>
                        <IconButton
                            component="a"
                            href="https://www.facebook.com/sectornoventa"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{color: 'white'}}
                        >
                            <Facebook />
                        </IconButton>
                        /sectornoventa
                        <br />
                        <IconButton
                            component="a"
                            href="https://www.instagram.com/sectornoventa/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{color: 'white'}}
                        >
                            <Instagram />
                        </IconButton>
                        /sectornoventa
                    </ParagraphText>
                </Grid>
            </Grid>
        </TextGrid>
        <Grid container style={{height: '150px'}}>

        </Grid>
    </Grid>
  );
}

export default Contact;