import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import Landing from './Landing';
import Pintura from './Pintura';
import Accesories from './Accesories';
import Artists from './Artists';
import Contact from './Contact';

function AppUI() {
  return (
    <React.Fragment>
      <Router>
        <Layout>
          <Routes>
            <Route path='/' exact element={<Landing/>} />
            <Route path='/pintura' element={<Pintura/>} />
            <Route path='/accesorios' element={<Accesories/>} />
            <Route path='/artistas' element={<Artists/>} />
            <Route path='/contacto' element={<Contact/>} />
          </Routes>
          </Layout>
      </Router>
    </React.Fragment>
  );
}

export default AppUI;