import React from 'react';
import {
  Home,
  LocalGroceryStore,
  SupportAgent,
  FormatPaint
} from '@mui/icons-material';
import ArtistIcon from './artist-icon.js';
export const SidebarData = [
  {
    title: 'Inicio',
    path: '/',
    icon: <Home style={{color: 'white', fontSize: '30px'}}/>,
    cName: 'nav-text'
  },
  {
    title: 'Pintura',
    path: '/pintura',
    icon: <FormatPaint style={{color: 'white', fontSize: '30px'}}/>,
    cName: 'nav-text'
  },
  {
    title: 'Accesorios',
    path: '/accesorios',
    icon: <LocalGroceryStore style={{color: 'white', fontSize: '30px'}}/>,
    cName: 'nav-text'
  },
  {
    title: 'Artistas',
    path: '/artistas',
    icon: <ArtistIcon style={{color: 'white', fontSize: '30px'}}/>,
    cName: 'nav-text'
  },
  {
    title: 'Contacto',
    path: '/contacto',
    icon: <SupportAgent style={{color: 'white', fontSize: '30px'}}/>,
    cName: 'nav-text'
  }
];
