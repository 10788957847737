import React, { useCallback, useState } from 'react';
// import { useLocalStorage } from './useLocalStorage';

const AppContext = React.createContext();

function AppProvider(props) {

  const [sidebar, setSidebar] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const showSidebar = () => setSidebar(!sidebar);
  
  const auth = true;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSelectedItems = useCallback((item) => {
    // Solo agregar ítems con `cart` mayor a 0
    if (item.cart > 0) {
      setSelectedItems((prevItems) => {
        // Verificar si el ítem ya está en la lista
        const itemExists = prevItems.find(selectedItem => selectedItem.id === item.id);
        if (itemExists) {
          return prevItems.map(selectedItem =>
            selectedItem.id === item.id ? item : selectedItem
          );
        }
        // Si no está en la lista, agregarlo
        return [...prevItems, item];
      });
    } else {
      // Eliminar ítem si su cantidad es 0 o menor
      setSelectedItems((prevItems) =>
        prevItems.filter(selectedItem => selectedItem.id !== item.id)
      );
    }
  }, []);
  
  return (
    <AppContext.Provider value={{
      auth,
      anchorEl,
      handleMenu,
      handleClose,
      sidebar,
      showSidebar,
      selectedItems,
      setSelectedItems,
      updateSelectedItems
    }}>
      {props.children}
    </AppContext.Provider>
  );
}

export { AppContext, AppProvider };
