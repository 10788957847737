import { Grid, IconButton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { 
    PaintItem, 
    AmountTextField,
    LabelGrid,
    LabelText,
    AmountGrid,
 } from '../../styled';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AppContext } from 'AppContext';

function PaintWidget({ helperTextLabel, item, last, orderData, setOrderData, type }) {
  const [amount, setAmount] = useState(item.cart || 0);
  const limit = item.stock; // El límite máximo es igual a `item.stock`
  const { updateSelectedItems } = useContext(AppContext); // Obtener la función del contexto

  useEffect(() => {
    updateSelectedItems({ ...item, cart: amount });
  }, [amount, item, updateSelectedItems]);

  const handleIncrement = () => {
    setAmount(prevAmount => {
      const newAmount = Math.min(prevAmount + 1, limit); // Incrementar, pero no superar el límite
      updateOrderData(item.id, newAmount);
      return newAmount;
    });
  };

  const handleDecrement = () => {
    setAmount(prevAmount => {
      const newAmount = Math.max(prevAmount - 1, 0); // Decrementar, pero no caer por debajo de 0
      updateOrderData(item.id, newAmount);
      return newAmount;
    });
  };

  const handleChange = (event) => {
    const newAmount = Math.min(Number(event.target.value), limit); // Asegurarse de que el nuevo valor no exceda el límite
    setAmount(newAmount);
    updateOrderData(item.id, newAmount);
  };

  const updateOrderData = (id, newAmount) => {
    setOrderData(prevOrderData => {
      const updatedItems = prevOrderData[type].map(item =>
        item.id === id ? { ...item, cart: newAmount } : item
      );
      return { ...prevOrderData, [type]: updatedItems };
    });
  };

  return (
    <PaintItem item xs={12} style={{borderBottom: last ? '2px solid black' : ''}}>
      <Grid container>
        <LabelGrid item xs bgcolor={item.color} textcolor={item.label_color}>
          {helperTextLabel ? <LabelText noWrap style={{fontSize: '13px'}}>
            {item.code_label + ' ' + item.name}
          </LabelText> : <LabelText noWrap>
            {item.code_label + ' ' + item.name}
          </LabelText>}
          {helperTextLabel && <LabelText noWrap style={{fontSize: '9px'}}>
            {item.color_type.name}
          </LabelText>}
        </LabelGrid>
        <AmountGrid item>
          <Grid container justifyContent="center" style={{ marginTop: '5px' }}>
            <Typography align="center" variant='h6'>
              {item.stock}
            </Typography>
          </Grid>
        </AmountGrid>
        <Grid item>
          <AmountTextField
            type="tel"
            value={amount ? amount : ''}
            onChange={handleChange}
            InputProps={{
              inputProps: { min: 0, max: limit },
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <IconButton
                style={{ backgroundColor: '#dfdfdf', color: '#424242', borderRadius: '0px', padding: '9.3px' }}
                onClick={handleIncrement}
              >
                <AddIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                style={{ backgroundColor: '#e7e7e7', color: '#424242', borderRadius: '0px', padding: '9.3px' }}
                onClick={handleDecrement}
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PaintItem>
  );
}

export default PaintWidget;