import styled from "@emotion/styled";
import DrawerMUI from "@mui/material/Drawer";
import ListItemMUI from "@mui/material/ListItem";
import IconButtonMUI from "@mui/material/IconButton";

export const IconButton = styled(IconButtonMUI)`
    position: absolute;
    top: 5px;
    right: 5px;
    & .MuiSvgIcon-root {
        color: white; 
        font-size: 30px;
    }
`;
export const Drawer = styled(DrawerMUI)`
    position: relative;
    width: 220px;
    & .MuiPaper-root {
    background-color: #181818;
    }
`;

export const ListItem = styled(ListItemMUI)`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px;
    list-style: none;
    height: 60px;
    width: 220px;
    a {
        text-decoration: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
    }
    span {
        font-size: 20px;
    }
    & .MuiButtonBase-root {
        width: 190px;
        border-radius: 4px;
        &:hover{
            background-color: #40484cd6;
        }
    }
`;