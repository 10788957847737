import { IconButton } from '@mui/material';
import React from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

function DropdownButton({open, handleOpen, label}) {
    const handleClick = () => handleOpen(!open, label);
    return (
        <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="dropdown"
              sx={{ mr: 2 }}
              onClick={handleClick}
              style={{marginLeft: '8px'}}
              >
                {open ? 
                <KeyboardArrowUp style={{height: '40px', width: '40px'}}/> :
                <KeyboardArrowDown style={{height: '40px', width: '40px'}}/>
                }
        </IconButton>
    );
}

export default DropdownButton;