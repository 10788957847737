import React from 'react';
import AppBar from '../../components/AppBar';
import { SideBar } from '../../components/SideBar';
import { AppContext } from '../../AppContext';

function Layout ({children}) {

  const {
    auth,
    anchorEl,
    handleMenu,
    handleClose,
    sidebar, 
    showSidebar
  } = React.useContext(AppContext);

    return (
        <React.Fragment>
            <AppBar 
            auth={auth}
            anchorEl={anchorEl}
            showSidebar={showSidebar}
            handleMenu={handleMenu}
            handleClose={handleClose}
            />
            <SideBar 
            sidebar={sidebar}
            showSidebar={showSidebar}
            />
            <div style={{
                backgroundColor: '#212121', 
                minHeight: '100vh', 
                display: 'flex', 
                flexDirection: 'column',
            }}>
              {children}
            </div>
        </React.Fragment>
    );
}

export {Layout};
