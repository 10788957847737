import { Grid, IconButton } from '@mui/material';
import React from 'react';
import { 
    PictureGrid, 
    LargeText, 
    Span, 
    globalStyles, 
    ActionButton, 
    TitleText,
    TextGrid,
    ParagraphText,
} from './styled';
import bgPicture from './bgPicture.png';
import { Global } from '@emotion/react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram } from '@mui/icons-material';

function Landing() {
  return (
    <Grid container spacing={2}>
        <PictureGrid 
            item 
            xs
            style={{
                backgroundImage: `url(${bgPicture})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                marginTop: '20px'
                }}>
            <PictureGrid container justifyContent='center' alignContent='center' >
                <Grid item xs={12}>
                    <Global styles={globalStyles} />
                        <Span>
                            MUSICA<br />
                            <LargeText >PINTURA</LargeText><br />
                            ARTE
                        </Span>
                </Grid>
            </PictureGrid>
            <Grid container justifyContent='center'>
                <Link to={'/pintura'}>
                    <ActionButton>
                        Realizar pedido
                    </ActionButton>
                </Link>
            </Grid>
        </PictureGrid>
        <TextGrid spacing={2} item xs={12} >
                <Grid container justifyContent='center'>
                    <Grid item>
                        <TitleText>
                            Quiénes Somos
                        </TitleText>
                    </Grid>
                    <Grid item xs={11}>
                        <ParagraphText>
                        En <span style={{fontWeight: '600'}}>Sector 90</span>, somos líderes en la venta de pintura artística en aerosol con un enfoque especial en el arte urbano, la música y los murales artísticos. Fundada bajo el concepto de <span style={{fontWeight: '600'}}>90 Squad</span>, hemos evolucionado para convertirnos en un referente en el apoyo al arte y los deportes urbanos en Sinaloa.
                        <br/><br/>
                        Nuestro objetivo es ser la mejor empresa de arte urbano y fomentar la inclusión en la comunidad artística y deportiva. Ofrecemos un espacio vibrante para artistas urbanos, músicos y deportistas, promoviendo el deporte, y fortaleciendo el impacto de la cultura urbana en nuestra región.
                        <br/><br/>
                        En <span style={{fontWeight: '600'}}>Sector 90</span>, estamos comprometidos con la creatividad y la innovación, y trabajamos continuamente para apoyar y elevar a los talentos emergentes en el mundo del arte y el deporte.
                        </ParagraphText>
                    </Grid>
                </Grid>
        </TextGrid>
        <TextGrid spacing={2} item xs={12}>
                <Grid container justifyContent='center'>
                    <Grid item xs={11}>   
                        <ParagraphText gutterBottom>
                            <span style={{
                                fontWeight: '600',
                                fontSize: '24px',
                            }}>Contáctanos<br/></span>
                        </ParagraphText>
                    </Grid> 
                    <Grid item xs={11}>
                        <ParagraphText>
                            Tel: (668) 836-4094<br/>
                            Correo: sector.noventa@gmail.com<br/>
                            <IconButton
                                component="a"
                                href="https://www.facebook.com/sectornoventa"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{color: 'white'}}
                            >
                                <Facebook />
                            </IconButton>
                            /sectornoventa
                            <br />
                            <IconButton
                                component="a"
                                href="https://www.instagram.com/sectornoventa/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{color: 'white'}}
                            >
                                <Instagram />
                            </IconButton>
                            /sectornoventa
                        </ParagraphText>
                    </Grid>
            </Grid> 
        </TextGrid>
        <Grid container style={{height: '50px'}}>
        </Grid>
    </Grid>
  );
}

export default Landing;