import React from 'react';
import { Link } from "react-router-dom";
import {
  List, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText,
  Divider,
  Box,
} from '@mui/material';
import { SidebarData } from './SidebarData';
import { Drawer, ListItem, IconButton } from './styled';
import { ArrowBack } from '@mui/icons-material';

function SideBar ({sidebar, showSidebar}) {

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    showSidebar();
  };

  const list = (anchor) => (
    <React.Fragment>
      <Box sx={{height: 50}}/>

      <IconButton 
        onClick={showSidebar}
        >
        <ArrowBack />
      </IconButton>

      <Divider />
      <List>
        {SidebarData.map((item) => (
          <ListItem key={item.path} disablePadding>
            <Link to={item.path}>
            <ListItemButton onClick={showSidebar}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );

  return (
    <div>
        <React.Fragment>
          <Drawer
            anchor={'left'}
            open={sidebar}
            onClose={toggleDrawer(false)}
          >
            {list()}
          </Drawer>
        </React.Fragment>
    </div>
  );
}

export {SideBar};